@import url("https://fonts.googleapis.com/icon?family=Material+Icons");

.gallery-container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;

  background-color: rgba(0,0,0,.25);
  width:100%;
}

.button-prev,
.button-next {
  position: absolute;
  font-family:"Material Icons";
  font-size: 4rem;
  cursor: pointer;
  user-select: none;
}

.button-prev {
  left: 5%;
}

.button-next {
  right: 5%;
}

.button-prev:hover,
.button-next:hover {
  color: white;
}

.gallery-track {
  aspect-ratio: 168/100;
  position: relative;
  width: 100%;
  overflow: hidden;
}

.slide-image {
  aspect-ratio: 168/100;
  position: absolute;
  background-size: cover;
  width: 100%;
}

.gallery-footer {
  position: absolute;
  bottom: 5%;
  display: flex;
  flex-direction: row;
  column-gap: 1rem;
}

.bubble-outer {
  cursor: pointer;
  border : 0 solid transparent;
  border-radius: 8px;
}

.bubble-inner {
  border: 0 solid transparent;
  border-radius: 4px;
}

@keyframes slide-right-in {
    from {
        -webkit-transform: translateX(-100%);
        -moz-transform: translateX(-100%);
        -ms-transform: translateX(-100%);
        -o-transform: translateX(-100%);
        transform: translateX(-100%);
    }
    to {
        -webkit-transform: translateX(0%);
        -moz-transform: translateX(0%);
        -ms-transform: translateX(0%);
        -o-transform: translateX(0%);
        transform: translateX(0%);
    }
}

@keyframes slide-left-in {
    from {
        -webkit-transform: translateX(100%);
        -moz-transform: translateX(100%);
        -ms-transform: translateX(100%);
        -o-transform: translateX(100%);
        transform: translateX(100%);
    }
    to {
        -webkit-transform: translateX(0%);
        -moz-transform: translateX(0%);
        -ms-transform: translateX(0%);
        -o-transform: translateX(0%);
        transform: translateX(0%);
    }
}

@keyframes slide-right-out {
    from {
        -webkit-transform: translateX(0%);
        -moz-transform: translateX(0%);
        -ms-transform: translateX(0%);
        -o-transform: translateX(0%);
        transform: translateX(0%);
    }
    to {
        -webkit-transform: translateX(100%);
        -moz-transform: translateX(100%);
        -ms-transform: translateX(100%);
        -o-transform: translateX(100%);
        transform: translateX(100%);
    }
}

@keyframes slide-left-out {
    from {
        -webkit-transform: translateX(0%);
        -moz-transform: translateX(0%);
        -ms-transform: translateX(0%);
        -o-transform: translateX(0%);
        transform: translateX(0%);
    }
    to {
        -webkit-transform: translateX(-100%);
        -moz-transform: translateX(-100%);
        -ms-transform: translateX(-100%);
        -o-transform: translateX(-100%);
        transform: translateX(-100%);
    }
}

.rightIn {
    -webkit-animation: slide-right-in .5s 1 forwards;
    -moz-animation: slide-right-in .5s 1 forwards;
    -ms-animation: slide-right-in .5s 1 forwards;
    -o-animation: slide-right-in .5s 1 forwards;
    animation: slide-right-in .5s 1 forwards;
}

.leftIn {
    -webkit-animation: slide-right-in .5s 1 forwards;
    -moz-animation: slide-right-in .5s 1 forwards;
    -ms-animation: slide-right-in .5s 1 forwards;
    -o-animation: slide-right-in .5s 1 forwards;
    animation: slide-left-in .5s 1 forwards;
}

.rightOut {
    -webkit-animation: slide-right-in .5s 1 forwards;
    -moz-animation: slide-right-in .5s 1 forwards;
    -ms-animation: slide-right-in .5s 1 forwards;
    -o-animation: slide-right-in .5s 1 forwards;
    animation: slide-right-out .5s 1 forwards;
}

.leftOut{
    -webkit-animation: slide-right-in .5s 1 forwards;
    -moz-animation: slide-right-in .5s 1 forwards;
    -ms-animation: slide-right-in .5s 1 forwards;
    -o-animation: slide-right-in .5s 1 forwards;
    animation: slide-left-out .5s 1 forwards;
}